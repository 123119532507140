import Image from "next/image";

type ProgramListCardProps = {
	source: string;
	title: string;
	sub: string;
	etc?: string;
	alt: string;
};

export default function ProgramListCard(props: ProgramListCardProps) {
	return (
		<div className="h-full rounded-lg shadow-lg xl:w-auto p-6 lg:p-8 pb-12">
			<Image
				src={props.source}
				height={80}
				width={80}
				alt={String(props.alt)}
				className="pb-8"
			/>
			<h3 className="font-semibold text-slate-ipbt text-lg">
				{props.title}
				<br />
				{props.etc}
			</h3>
			<div className="my-2 lg:my-4 bg-yellow-500 h-[2px] w-1/2 rounded-full" />
			<div className="lg:text-lg text-slate-500">{props.sub}</div>
		</div>
	);
}
