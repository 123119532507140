import FactCard from "@/components/card/FactCard";
import FACT_DATA from "@/model/FactData";

export function SectionStatistic() {
	return (
		<div className="grid grid-cols-2 px-4 gap-4 xl:gap-8 mx-auto max-w-screen-3xl pb-16 xl:flex xl:flex-row xl:justify-around">
			<FactCard {...FACT_DATA[0]} />
			<FactCard {...FACT_DATA[1]} />
			<FactCard {...FACT_DATA[2]} />
			<FactCard {...FACT_DATA[3]} />
		</div>
	);
}
