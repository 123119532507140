type FactCardProps = {
	title?: string;
	sub?: string;
	etc?: string;
};

export default function FactCard(props: FactCardProps) {
	return (
		<div className="text-center">
			<h2 className="text-4xl font-bold text-blue-ipbt xl:text-10xl">
				{props.title}
			</h2>
			<p className="font-bold text-lg xl:text-xl">
				{props.sub} <br /> {props.etc}
			</p>
		</div>
	);
}
