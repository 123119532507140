import { useRouter } from "next/router";
import { useEffect, useState } from "react";

import { ClientsSection } from "@/components/section/ClientsSection";
import { SectionArticle } from "@/components/section/SectionArticle";
import { SectionDescription } from "@/components/section/SectionDesciption";
import { SectionNearestProduct } from "@/components/section/SectionNearestProduct";
import { SectionRequestInhouse } from "@/components/section/SectionRequestInhouse";
import { SectionReview } from "@/components/section/SectionReview";
import { SectionStatistic } from "@/components/section/SectionStatistic";
import { TechnicalHeroSection } from "@/components/section/technical/TechnicalHeroSection";
import { useAuth } from "@/context/auth";
import { MainLayout } from "@/layout/MainLayout";
import { SERVICE_AUTHENTICATION, SERVICE_BLOG } from "@/services";

const VARIANT_PAGE = "technical";

export default function IndexPage() {
	const router = useRouter();
	const { handleSetAuth } = useAuth();
	const { loadingLoadAuth } = useAuth();
	const [dataArticle, setDataArticle] = useState([]);

	const handleFetchDataArticle = () => {
		SERVICE_BLOG.searchArticle("", (res: Response) => {
			setDataArticle(res.payload?.data ?? []);
		});
	};

	useEffect(() => {
		const token = Array.isArray(router.query.token)
			? router.query.token.join(", ")
			: router.query.token;

		if (token) {
			SERVICE_AUTHENTICATION.detailUserByTokenSkilly(token, (res: Response) => {
				if (res.success && res.payload.success) {
					const { id, name, email, photo } = res.payload.user;
					handleSetAuth({ id, name, email, photo, token });
				}
			});
		}
	}, [router.query.token]);

	useEffect(() => {
		if (!loadingLoadAuth) {
			handleFetchDataArticle();
		}
	}, [loadingLoadAuth]);

	return (
		<MainLayout
			meta={{
				description:
					"Excellence hub for professional and vocational skills based on applied life science",
			}}
			contact="technical"
		>
			<TechnicalHeroSection />
			<SectionStatistic />
			<ClientsSection variant={VARIANT_PAGE} />
			<SectionDescription />
			<SectionNearestProduct variant={VARIANT_PAGE} />
			<SectionReview variant={VARIANT_PAGE} />
			<SectionArticle article={dataArticle} />
			<SectionRequestInhouse variant={VARIANT_PAGE} />
		</MainLayout>
	);
}
