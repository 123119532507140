import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import TelegramIcon from '@mui/icons-material/Telegram';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import XIcon from '@mui/icons-material/X';
import Image from 'next/image';
import {
  FacebookShareButton,
  LinkedinShareButton,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from 'react-share';

import { Helpers } from '@/utils';

type ShareableArticleCardProps = {
  image: string;
  imgAlt: string;
  heading: string;
  date?: string;
  fbLink: string;
  igLink: string;
  twLink: string;
  id: number;
};

export default function ShareableArticleCard(props: ShareableArticleCardProps) {
  const shareUrl = `https://ipbtraining.com/article/${props.id}-${Helpers.slugify(props.heading)}`;
  return (
    <div className="flex flex-col w-[20rem] h-[28rem] lg:h-[32rem] items-center rounded-xl overflow-hidden bg-white mx-2 shadow-lg">
      <div className="relative mb-0 w-full h-auto aspect-4/3 bg-white">
        <Image
          blurDataURL="/assets/images/placeholder.png"
          placeholder="blur"
          src={props.image}
          alt={props.imgAlt}
          fill
          className="object-cover rounded-t-xl"
        />
      </div>
      <div className="rounded-lg p-4 h-full flex flex-col justify-between">
        <div className="">
          <h1 className="font-semibold xl:text-lg">{props.heading}</h1>
          <p className="text-lg text-gray-400">{props.date}</p>
        </div>
        <div className="flex gap-2 py-2 text-slate-500">
          <FacebookShareButton url={shareUrl}>
            <FacebookIcon className="hover:text-blue-800" />
          </FacebookShareButton>
          <TwitterShareButton url={shareUrl}>
            <XIcon className="hover:text-black" />
          </TwitterShareButton>
          <LinkedinShareButton url={shareUrl}>
            <LinkedInIcon className="hover:text-blue-400" />
          </LinkedinShareButton>
          <TelegramShareButton url={shareUrl}>
            <TelegramIcon className="hover:text-blue-ipbt" />
          </TelegramShareButton>
          <WhatsappShareButton url={shareUrl}>
            <WhatsAppIcon className="hover:text-green-500" />
          </WhatsappShareButton>
        </div>
      </div>
    </div>
  );
}
