import { HeadSection } from "@/components/typography/HeadSection";

export default function HomeArticle() {
	return (
		<div className="w-full pb-8 flex-1">
			<hr className="w-64 border-t-8 border-blue-ipbt" />
			<HeadSection className="py-6">Sekilas IPB Training</HeadSection>
			<p className="text-justify lg:text-lg text-gray-500 leading-loose">
				<b>IPB Training</b>, PT Global Scholarship Services Indonesia, merupakan
				penyedia solusi terintegrasi untuk pengembangan kompetensi Sumber Daya
				Manusia (SDM). <b>Berpengalaman</b> sejak 12 Maret 2009, IPB Training
				terus bertumbuh, berkembang dan mengambil peran dalam meningkatkan
				kompetensi personel agar sesuai dengan kebutuhan dan tuntutan profesi di
				dunia usaha dan dunia industri.
				<br />
				<br />
				IPB Training menyelenggarakan berbagai program pengembangan SDM yang
				dibagi menjadi tiga portofolio utama yakni Technical Skill Development
				Program (TSDP), Language Skill Development Program (LSDP), serta
				Leadership Management Development Program (LMDP). Berbagai program
				kursus, pelatihan, pendampingan hingga tes / ujian bersertifikat
				diselenggarakan oleh IPB Training baik bagi peserta umum maupun sesuai
				dengan kebutuhan dan preferensi pelanggan.
				<br />
				<br />
				<b>
					Didukung oleh tenaga ahli dan pakar akademisi, serta para praktisi
					dari dunia profesional
				</b>
				, IPB Training <b>telah membersamai ribuan personel</b> untuk belajar
				dan meningkatkan kapasitas dirinya sehingga lebih berdaya, berdampak dan
				berkinerja unggul. IPB Training senantiasa berupaya menyediakan berbagai
				modul pelatihan yang{" "}
				<b>
					mengkombinasikan dasar kepakaran IPB yang unik, standar kerja dan
					kompetensi nasional hingga tuntutan profesi khusus yang dilengkapi
					dengan model pembelajaran praktikal (hands-on)
				</b>
				untuk memastikan pengalaman belajar yang berkualitas dan mudah untuk
				dipahami serta diaplikasikan.
			</p>
		</div>
	);
}
