import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Link from "next/link";
import Slider from "react-slick";

import ShareableArticleCard from "@/components/card/ShareableArticleCard";
import { Helpers } from "@/utils";

import { HeadSection } from "../typography/HeadSection";

function NavigationArrow() {
	return <button className="block bg-white" type="button" />;
}

const sliderSettings = {
	dots: false,
	infinite: true,
	variableWidth: true,
	autoplay: true,
	arrows: false,
	speed: 1000,
	autoplaySpeed: 3200,
	cssEase: "linear",
	responsive: [
		{
			breakpoint: 780,
			settings: {
				arrows: true,
				className: "slides",
				nextArrow: <NavigationArrow />,
				prevArrow: <NavigationArrow />,
			},
		},
		{
			breakpoint: 1024,
			settings: {
				arrows: true,
				className: "slides",
				nextArrow: <NavigationArrow />,
				prevArrow: <NavigationArrow />,
			},
		},
	],
};

export function SectionArticle({ article }: { article: Article[] }) {
	return (
		<div className="max-w-screen-4xl mx-auto py-10 lg:py-20 px-4">
			<div className="mx-auto bg-white">
				<HeadSection>Artikel terbaru</HeadSection>
				<p className="text-center text-lg text-gray-500 xl:text-left xl:text-2xl pb-6 xl:pb-8">
					Dapatkan informasi menarik dari artikel yang kami tayangkan di sini
				</p>
				<Slider {...sliderSettings}>
					{article.map((item) => {
						if (item.status === "Draft") return null;
						return (
							<Link
								className="pb-4"
								key={item.id}
								href={`article/${item.id}-${Helpers.slugify(item.title)}`}
							>
								<ShareableArticleCard
									heading={item.title}
									image={item.thumbnail}
									fbLink=""
									igLink=""
									twLink=""
									imgAlt={item.title}
									id={item.id}
									date={Helpers.formatDateHuman(item.created_at)}
								/>
							</Link>
						);
					})}
				</Slider>
			</div>
		</div>
	);
}
