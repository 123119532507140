import Link from "next/link";

import HomeArticle from "@/article/HomeArticle";
import ProgramListCard from "@/components/card/ProgramListCard";
import PROGRAMS_DATA from "@/model/ProgramsData";
import { CONSTANTS } from "@/utils";

export function SectionDescription() {
	return (
		<div className="flex flex-col xl:flex-row lg:gap-8 max-w-screen-4xl mx-auto py-20 px-8">
			<HomeArticle />
			<div className="flex-1 grid grid-cols-1 xl:grid-cols-2 xl:grid-rows-[32rem_32rem] gap-6">
				<Link href="/technical" className="block basis-1/2">
					<ProgramListCard {...PROGRAMS_DATA[0]} />
				</Link>
				<Link href="ldp" className="block basis-1/2">
					<ProgramListCard {...PROGRAMS_DATA[1]} />
				</Link>
				<Link href="ila" className="block basis-1/2">
					<ProgramListCard {...PROGRAMS_DATA[2]} />
				</Link>
				<Link href={CONSTANTS.VIDEO_ON_DEMAND} className="block basis-1/2">
					<ProgramListCard {...PROGRAMS_DATA[3]} />
				</Link>
			</div>
		</div>
	);
}
