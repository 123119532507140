const FACT_DATA = [
	{
		title: "10K",
		sub: "Happy Customer",
		etc: "",
	},
	{
		title: "12",
		sub: "Year Experience",
		etc: "",
	},
	{
		title: "3.8",
		sub: "Customer Feedback",
		etc: "(out of 4.00)",
	},
	{
		title: "100+",
		sub: "Expert & Practitioners",
		etc: "",
	},
];

export default FACT_DATA;
