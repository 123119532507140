const PROGRAMS_DATA = [
	{
		source: "/assets/images/technical_icon.webp",
		title: "Pelatihan teknikal",
		sub: "Program pelatihan teknikal dan vokasional bagi para profesional, pekerja teknis, maupun individu pembelajar",
		etc: "",
		alt: "Icon pelatihan teknikal",
	},
	{
		source: "/assets/images/ldp_icon.webp",
		title: "Program kepemimpinan",
		sub: "Program pengembangan kemimpinan dan manajerial bagi talenta terbaik di organisasi",
		etc: "dan manajerial",
		alt: "Icon program kepemimpinan",
	},
	{
		source: "/assets/images/ila_icon.webp",
		title: "Program bahasa",
		sub: "Bekali diri dengan keterampilan bahasa untuk berperan di level global",
		etc: "",
		alt: "Icon program bahasa",
	},
	{
		source: "/assets/images/vod_icon.webp",
		title: "Belajar lewat video",
		sub: "Beli akses sekali, belajar dapat dilakukan kapan saja dan dimana saja",
		etc: "",
		alt: "Icon belajar lewat video",
	},
] as const;

export default PROGRAMS_DATA;
