import MainButtons from '@/button/MainButtons';
import MainPortalImage from '@/layout/MainPortalImage';

export function TechnicalHeroSection() {
  return (
    <div className="mt-24 lg:mt-36 flex flex-col-reverse px-4 xl:px-16 pt-0 xl:flex-row max-w-screen-6xl items-center mx-auto xl:py-10">
      <div className="flex w-full flex-col items-center xl:w-1/2 xl:items-start">
        <span className="w-full border-blue-ipbt text-center xl:text-left font-semibold text-blue-ipbt xl:whitespace-nowrap text-xl xl:text-2xl">
          Butuh wadah untuk meningkatkan kompetensi?
        </span>
        <h1 className="text-center text-6xl xl:text-9xl font-bold tracking-tight text-slate-ipbt xl:text-left leading-tight">
          10.000+ orang <br />
          telah belajar <br />
          bersama kami
        </h1>
        <p className="mb-0 py-4 text-center text-lg text-gray-400 xl:max-w-[32ch] xl:p-0 xl:text-left xl:text-3xl">
          Excellence hub for people development based on applied life science
        </p>
        <div className="py-6 flex items-center justify-center xl:justify-start w-full">
          <MainButtons
            button="Tentang kami"
            bgButton="bg-blue-ipbt"
            redirect="about"
          />
        </div>
      </div>
      <MainPortalImage
        size="100%"
        src="/assets/images/hero_image_technical.webp"
        alt="IPBT image"
        className="mx-auto flex w-full pb-4 xl:pb-0 object-cover xl:w-3/5"
      />
    </div>
  );
}
